/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
  BikeBenefitContractReturnOrRedemptionReasonToJSONTyped,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { ReturnProcessPickupDetailsRead } from "./ReturnProcessPickupDetailsRead";
import {
  ReturnProcessPickupDetailsReadFromJSON,
  ReturnProcessPickupDetailsReadFromJSONTyped,
  ReturnProcessPickupDetailsReadToJSON,
  ReturnProcessPickupDetailsReadToJSONTyped,
} from "./ReturnProcessPickupDetailsRead";
import type { ReturnProcessStatus } from "./ReturnProcessStatus";
import {
  ReturnProcessStatusFromJSON,
  ReturnProcessStatusFromJSONTyped,
  ReturnProcessStatusToJSON,
  ReturnProcessStatusToJSONTyped,
} from "./ReturnProcessStatus";

/**
 *
 * @export
 * @interface ReturnProcessRead
 */
export interface ReturnProcessRead {
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  initiatedByUserId: string;
  /**
   *
   * @type {boolean}
   * @memberof ReturnProcessRead
   */
  isEndOfLeaseReturn: boolean;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessRead
   */
  leaseEndDate: Date;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof ReturnProcessRead
   */
  returnReason: BikeBenefitContractReturnOrRedemptionReason;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  id: string;
  /**
   *
   * @type {ReturnProcessStatus}
   * @memberof ReturnProcessRead
   */
  status: ReturnProcessStatus;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessRead
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  saleInvoiceId?: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessRead
   */
  hubspotTicketId?: string;
  /**
   *
   * @type {ReturnProcessPickupDetailsRead}
   * @memberof ReturnProcessRead
   */
  pickupDetails?: ReturnProcessPickupDetailsRead;
}

/**
 * Check if a given object implements the ReturnProcessRead interface.
 */
export function instanceOfReturnProcessRead(
  value: object,
): value is ReturnProcessRead {
  if (
    !("bikeBenefitContractId" in value) ||
    value["bikeBenefitContractId"] === undefined
  )
    return false;
  if (
    !("initiatedByUserId" in value) ||
    value["initiatedByUserId"] === undefined
  )
    return false;
  if (
    !("isEndOfLeaseReturn" in value) ||
    value["isEndOfLeaseReturn"] === undefined
  )
    return false;
  if (!("leaseEndDate" in value) || value["leaseEndDate"] === undefined)
    return false;
  if (!("returnReason" in value) || value["returnReason"] === undefined)
    return false;
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  return true;
}

export function ReturnProcessReadFromJSON(json: any): ReturnProcessRead {
  return ReturnProcessReadFromJSONTyped(json, false);
}

export function ReturnProcessReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessRead {
  if (json == null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    initiatedByUserId: json["initiated_by_user_id"],
    isEndOfLeaseReturn: json["is_end_of_lease_return"],
    leaseEndDate: new Date(json["lease_end_date"]),
    returnReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["return_reason"],
    ),
    id: json["id"],
    status: ReturnProcessStatusFromJSON(json["status"]),
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    saleInvoiceId:
      json["sale_invoice_id"] == null ? undefined : json["sale_invoice_id"],
    hubspotTicketId:
      json["hubspot_ticket_id"] == null ? undefined : json["hubspot_ticket_id"],
    pickupDetails:
      json["pickup_details"] == null
        ? undefined
        : ReturnProcessPickupDetailsReadFromJSON(json["pickup_details"]),
  };
}

export function ReturnProcessReadToJSON(json: any): ReturnProcessRead {
  return ReturnProcessReadToJSONTyped(json, false);
}

export function ReturnProcessReadToJSONTyped(
  value?: ReturnProcessRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    initiated_by_user_id: value["initiatedByUserId"],
    is_end_of_lease_return: value["isEndOfLeaseReturn"],
    lease_end_date: value["leaseEndDate"].toISOString().substring(0, 10),
    return_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value["returnReason"],
    ),
    id: value["id"],
    status: ReturnProcessStatusToJSON(value["status"]),
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    sale_invoice_id: value["saleInvoiceId"],
    hubspot_ticket_id: value["hubspotTicketId"],
    pickup_details: ReturnProcessPickupDetailsReadToJSON(
      value["pickupDetails"],
    ),
  };
}
