/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
  BikeBenefitContractReturnOrRedemptionReasonToJSONTyped,
} from "./BikeBenefitContractReturnOrRedemptionReason";
import type { ReturnProcessPickupDetailsWrite } from "./ReturnProcessPickupDetailsWrite";
import {
  ReturnProcessPickupDetailsWriteFromJSON,
  ReturnProcessPickupDetailsWriteFromJSONTyped,
  ReturnProcessPickupDetailsWriteToJSON,
  ReturnProcessPickupDetailsWriteToJSONTyped,
} from "./ReturnProcessPickupDetailsWrite";

/**
 *
 * @export
 * @interface ReturnProcessPlanInvoicingIn
 */
export interface ReturnProcessPlanInvoicingIn {
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {boolean}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  isEndOfLeaseReturn: boolean;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  leaseEndDate: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  redeemedAccessoryIds: Array<string>;
  /**
   *
   * @type {ReturnProcessPickupDetailsWrite}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  pickupDetails?: ReturnProcessPickupDetailsWrite;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof ReturnProcessPlanInvoicingIn
   */
  returnReason: BikeBenefitContractReturnOrRedemptionReason;
}

/**
 * Check if a given object implements the ReturnProcessPlanInvoicingIn interface.
 */
export function instanceOfReturnProcessPlanInvoicingIn(
  value: object,
): value is ReturnProcessPlanInvoicingIn {
  if (
    !("bikeBenefitContractId" in value) ||
    value["bikeBenefitContractId"] === undefined
  )
    return false;
  if (
    !("isEndOfLeaseReturn" in value) ||
    value["isEndOfLeaseReturn"] === undefined
  )
    return false;
  if (!("leaseEndDate" in value) || value["leaseEndDate"] === undefined)
    return false;
  if (
    !("redeemedAccessoryIds" in value) ||
    value["redeemedAccessoryIds"] === undefined
  )
    return false;
  if (!("returnReason" in value) || value["returnReason"] === undefined)
    return false;
  return true;
}

export function ReturnProcessPlanInvoicingInFromJSON(
  json: any,
): ReturnProcessPlanInvoicingIn {
  return ReturnProcessPlanInvoicingInFromJSONTyped(json, false);
}

export function ReturnProcessPlanInvoicingInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPlanInvoicingIn {
  if (json == null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    isEndOfLeaseReturn: json["is_end_of_lease_return"],
    leaseEndDate: new Date(json["lease_end_date"]),
    redeemedAccessoryIds: json["redeemed_accessory_ids"],
    pickupDetails:
      json["pickup_details"] == null
        ? undefined
        : ReturnProcessPickupDetailsWriteFromJSON(json["pickup_details"]),
    returnReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["return_reason"],
    ),
  };
}

export function ReturnProcessPlanInvoicingInToJSON(
  json: any,
): ReturnProcessPlanInvoicingIn {
  return ReturnProcessPlanInvoicingInToJSONTyped(json, false);
}

export function ReturnProcessPlanInvoicingInToJSONTyped(
  value?: ReturnProcessPlanInvoicingIn | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    is_end_of_lease_return: value["isEndOfLeaseReturn"],
    lease_end_date: value["leaseEndDate"].toISOString().substring(0, 10),
    redeemed_accessory_ids: value["redeemedAccessoryIds"],
    pickup_details: ReturnProcessPickupDetailsWriteToJSON(
      value["pickupDetails"],
    ),
    return_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value["returnReason"],
    ),
  };
}
