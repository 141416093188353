/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface RedemptionProcessCalculation
 */
export interface RedemptionProcessCalculation {
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessCalculation
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  availableMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionPrice: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionPercentage: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  monthsSinceDelivered: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  leasingFeesPaid: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  redemptionFee: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  discount: number;
  /**
   *
   * @type {number}
   * @memberof RedemptionProcessCalculation
   */
  overspentMaintenanceBudget: number;
}

/**
 * Check if a given object implements the RedemptionProcessCalculation interface.
 */
export function instanceOfRedemptionProcessCalculation(
  value: object,
): value is RedemptionProcessCalculation {
  if (
    !("plannedRedemptionDate" in value) ||
    value["plannedRedemptionDate"] === undefined
  )
    return false;
  if (
    !("availableMaintenanceBudget" in value) ||
    value["availableMaintenanceBudget"] === undefined
  )
    return false;
  if (!("redemptionPrice" in value) || value["redemptionPrice"] === undefined)
    return false;
  if (
    !("redemptionPercentage" in value) ||
    value["redemptionPercentage"] === undefined
  )
    return false;
  if (
    !("monthsSinceDelivered" in value) ||
    value["monthsSinceDelivered"] === undefined
  )
    return false;
  if (!("leasingFeesPaid" in value) || value["leasingFeesPaid"] === undefined)
    return false;
  if (!("redemptionFee" in value) || value["redemptionFee"] === undefined)
    return false;
  if (!("discount" in value) || value["discount"] === undefined) return false;
  if (
    !("overspentMaintenanceBudget" in value) ||
    value["overspentMaintenanceBudget"] === undefined
  )
    return false;
  return true;
}

export function RedemptionProcessCalculationFromJSON(
  json: any,
): RedemptionProcessCalculation {
  return RedemptionProcessCalculationFromJSONTyped(json, false);
}

export function RedemptionProcessCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessCalculation {
  if (json == null) {
    return json;
  }
  return {
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    availableMaintenanceBudget: json["available_maintenance_budget"],
    redemptionPrice: json["redemption_price"],
    redemptionPercentage: json["redemption_percentage"],
    monthsSinceDelivered: json["months_since_delivered"],
    leasingFeesPaid: json["leasing_fees_paid"],
    redemptionFee: json["redemption_fee"],
    discount: json["discount"],
    overspentMaintenanceBudget: json["overspent_maintenance_budget"],
  };
}

export function RedemptionProcessCalculationToJSON(
  json: any,
): RedemptionProcessCalculation {
  return RedemptionProcessCalculationToJSONTyped(json, false);
}

export function RedemptionProcessCalculationToJSONTyped(
  value?: RedemptionProcessCalculation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    planned_redemption_date: value["plannedRedemptionDate"]
      .toISOString()
      .substring(0, 10),
    available_maintenance_budget: value["availableMaintenanceBudget"],
    redemption_price: value["redemptionPrice"],
    redemption_percentage: value["redemptionPercentage"],
    months_since_delivered: value["monthsSinceDelivered"],
    leasing_fees_paid: value["leasingFeesPaid"],
    redemption_fee: value["redemptionFee"],
    discount: value["discount"],
    overspent_maintenance_budget: value["overspentMaintenanceBudget"],
  };
}
