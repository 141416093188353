/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BenefitDefinitionAccessRequestStatus,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedUserBenefitDefinitionAccessRequestOut,
  UserBenefitDefinitionAccessRequestCreate,
  UserBenefitDefinitionAccessRequestRead,
} from "../models/index";
import {
  BenefitDefinitionAccessRequestStatusFromJSON,
  BenefitDefinitionAccessRequestStatusToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedUserBenefitDefinitionAccessRequestOutFromJSON,
  PaginatedUserBenefitDefinitionAccessRequestOutToJSON,
  UserBenefitDefinitionAccessRequestCreateFromJSON,
  UserBenefitDefinitionAccessRequestCreateToJSON,
  UserBenefitDefinitionAccessRequestReadFromJSON,
  UserBenefitDefinitionAccessRequestReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface BenefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequestRequest {
  userBenefitDefinitionAccessRequestCreate: UserBenefitDefinitionAccessRequestCreate;
}

export interface BenefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequestRequest {
  accessRequestId: string;
}

export interface BenefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRequest {
  status?: Array<BenefitDefinitionAccessRequestStatus>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class BenefitDefinitionAccessRequestsApi extends runtime.BaseAPI {
  /**
   * Create Benefit Definition Access Request
   */
  async benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequestRaw(
    requestParameters: BenefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserBenefitDefinitionAccessRequestRead>> {
    if (requestParameters["userBenefitDefinitionAccessRequestCreate"] == null) {
      throw new runtime.RequiredError(
        "userBenefitDefinitionAccessRequestCreate",
        'Required parameter "userBenefitDefinitionAccessRequestCreate" was null or undefined when calling benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequest().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definition-access-requests`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserBenefitDefinitionAccessRequestCreateToJSON(
          requestParameters["userBenefitDefinitionAccessRequestCreate"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserBenefitDefinitionAccessRequestReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Benefit Definition Access Request
   */
  async benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequest(
    requestParameters: BenefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserBenefitDefinitionAccessRequestRead> {
    const response =
      await this.benefitDefinitionAccessRequestsCreateBenefitDefinitionAccessRequestRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }

  /**
   * Delete Benefit Definition Access Request
   */
  async benefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequestRaw(
    requestParameters: BenefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["accessRequestId"] == null) {
      throw new runtime.RequiredError(
        "accessRequestId",
        'Required parameter "accessRequestId" was null or undefined when calling benefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequest().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definition-access-requests/{access_request_id}`.replace(
          `{${"access_request_id"}}`,
          encodeURIComponent(String(requestParameters["accessRequestId"])),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Benefit Definition Access Request
   */
  async benefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequest(
    requestParameters: BenefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequestRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.benefitDefinitionAccessRequestsDeleteBenefitDefinitionAccessRequestRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Get Benefit Definition Access Requests
   */
  async benefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRaw(
    requestParameters: BenefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<PaginatedUserBenefitDefinitionAccessRequestOut>
  > {
    const queryParameters: any = {};

    if (requestParameters["status"] != null) {
      queryParameters["status"] = requestParameters["status"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/benefit-definition-access-requests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserBenefitDefinitionAccessRequestOutFromJSON(jsonValue),
    );
  }

  /**
   * Get Benefit Definition Access Requests
   */
  async benefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequests(
    requestParameters: BenefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserBenefitDefinitionAccessRequestOut> {
    const response =
      await this.benefitDefinitionAccessRequestsGetBenefitDefinitionAccessRequestsRaw(
        requestParameters,
        initOverrides,
      );
    return await response.value();
  }
}
