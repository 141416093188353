/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBrand } from "./BikeBrand";
import {
  BikeBrandFromJSON,
  BikeBrandFromJSONTyped,
  BikeBrandToJSON,
  BikeBrandToJSONTyped,
} from "./BikeBrand";
import type { ShopType } from "./ShopType";
import {
  ShopTypeFromJSON,
  ShopTypeFromJSONTyped,
  ShopTypeToJSON,
  ShopTypeToJSONTyped,
} from "./ShopType";

/**
 *
 * @export
 * @interface UserShopRead
 */
export interface UserShopRead {
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  website?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  streetAddress?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof UserShopRead
   */
  country?: string;
  /**
   *
   * @type {Array<ShopType>}
   * @memberof UserShopRead
   */
  types: Array<ShopType>;
  /**
   *
   * @type {Array<BikeBrand>}
   * @memberof UserShopRead
   */
  bikeBrands: Array<BikeBrand>;
}

/**
 * Check if a given object implements the UserShopRead interface.
 */
export function instanceOfUserShopRead(value: object): value is UserShopRead {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("types" in value) || value["types"] === undefined) return false;
  if (!("bikeBrands" in value) || value["bikeBrands"] === undefined)
    return false;
  return true;
}

export function UserShopReadFromJSON(json: any): UserShopRead {
  return UserShopReadFromJSONTyped(json, false);
}

export function UserShopReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserShopRead {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    logoUrl: json["logo_url"] == null ? undefined : json["logo_url"],
    phoneNumber:
      json["phone_number"] == null ? undefined : json["phone_number"],
    website: json["website"] == null ? undefined : json["website"],
    email: json["email"] == null ? undefined : json["email"],
    streetAddress:
      json["street_address"] == null ? undefined : json["street_address"],
    postalCode: json["postal_code"] == null ? undefined : json["postal_code"],
    city: json["city"] == null ? undefined : json["city"],
    country: json["country"] == null ? undefined : json["country"],
    types: (json["types"] as Array<any>).map(ShopTypeFromJSON),
    bikeBrands: (json["bike_brands"] as Array<any>).map(BikeBrandFromJSON),
  };
}

export function UserShopReadToJSON(json: any): UserShopRead {
  return UserShopReadToJSONTyped(json, false);
}

export function UserShopReadToJSONTyped(
  value?: UserShopRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    logo_url: value["logoUrl"],
    phone_number: value["phoneNumber"],
    website: value["website"],
    email: value["email"],
    street_address: value["streetAddress"],
    postal_code: value["postalCode"],
    city: value["city"],
    country: value["country"],
    types: (value["types"] as Array<any>).map(ShopTypeToJSON),
    bike_brands: (value["bikeBrands"] as Array<any>).map(BikeBrandToJSON),
  };
}
