/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRulesRequestRequirements } from "./BenefitDefinitionAccessRulesRequestRequirements";
import {
  BenefitDefinitionAccessRulesRequestRequirementsFromJSON,
  BenefitDefinitionAccessRulesRequestRequirementsFromJSONTyped,
  BenefitDefinitionAccessRulesRequestRequirementsToJSON,
  BenefitDefinitionAccessRulesRequestRequirementsToJSONTyped,
} from "./BenefitDefinitionAccessRulesRequestRequirements";

/**
 *
 * @export
 * @interface BenefitDefinitionAccessRulesUserRead
 */
export interface BenefitDefinitionAccessRulesUserRead {
  /**
   *
   * @type {BenefitDefinitionAccessRulesRequestRequirements}
   * @memberof BenefitDefinitionAccessRulesUserRead
   */
  requestRequirement: BenefitDefinitionAccessRulesRequestRequirements;
}

/**
 * Check if a given object implements the BenefitDefinitionAccessRulesUserRead interface.
 */
export function instanceOfBenefitDefinitionAccessRulesUserRead(
  value: object,
): value is BenefitDefinitionAccessRulesUserRead {
  if (
    !("requestRequirement" in value) ||
    value["requestRequirement"] === undefined
  )
    return false;
  return true;
}

export function BenefitDefinitionAccessRulesUserReadFromJSON(
  json: any,
): BenefitDefinitionAccessRulesUserRead {
  return BenefitDefinitionAccessRulesUserReadFromJSONTyped(json, false);
}

export function BenefitDefinitionAccessRulesUserReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionAccessRulesUserRead {
  if (json == null) {
    return json;
  }
  return {
    requestRequirement: BenefitDefinitionAccessRulesRequestRequirementsFromJSON(
      json["request_requirement"],
    ),
  };
}

export function BenefitDefinitionAccessRulesUserReadToJSON(
  json: any,
): BenefitDefinitionAccessRulesUserRead {
  return BenefitDefinitionAccessRulesUserReadToJSONTyped(json, false);
}

export function BenefitDefinitionAccessRulesUserReadToJSONTyped(
  value?: BenefitDefinitionAccessRulesUserRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    request_requirement: BenefitDefinitionAccessRulesRequestRequirementsToJSON(
      value["requestRequirement"],
    ),
  };
}
