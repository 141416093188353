/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface ReturnProcessPickupDetailsRead
 */
export interface ReturnProcessPickupDetailsRead {
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPickupDetailsRead
   */
  requestedPickupDate: Date;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof ReturnProcessPickupDetailsRead
   */
  country: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  postCode: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  setByUserId: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsRead
   */
  returnProcessId: string;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPickupDetailsRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPickupDetailsRead
   */
  updatedAt?: Date;
}

/**
 * Check if a given object implements the ReturnProcessPickupDetailsRead interface.
 */
export function instanceOfReturnProcessPickupDetailsRead(
  value: object,
): value is ReturnProcessPickupDetailsRead {
  if (
    !("requestedPickupDate" in value) ||
    value["requestedPickupDate"] === undefined
  )
    return false;
  if (!("country" in value) || value["country"] === undefined) return false;
  if (!("address" in value) || value["address"] === undefined) return false;
  if (!("postCode" in value) || value["postCode"] === undefined) return false;
  if (!("city" in value) || value["city"] === undefined) return false;
  if (!("phoneNumber" in value) || value["phoneNumber"] === undefined)
    return false;
  if (!("setByUserId" in value) || value["setByUserId"] === undefined)
    return false;
  if (!("returnProcessId" in value) || value["returnProcessId"] === undefined)
    return false;
  return true;
}

export function ReturnProcessPickupDetailsReadFromJSON(
  json: any,
): ReturnProcessPickupDetailsRead {
  return ReturnProcessPickupDetailsReadFromJSONTyped(json, false);
}

export function ReturnProcessPickupDetailsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPickupDetailsRead {
  if (json == null) {
    return json;
  }
  return {
    requestedPickupDate: new Date(json["requested_pickup_date"]),
    country: SupportedCountriesEnumFromJSON(json["country"]),
    address: json["address"],
    postCode: json["post_code"],
    city: json["city"],
    phoneNumber: json["phone_number"],
    id: json["id"] == null ? undefined : json["id"],
    setByUserId: json["set_by_user_id"],
    returnProcessId: json["return_process_id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
  };
}

export function ReturnProcessPickupDetailsReadToJSON(
  json: any,
): ReturnProcessPickupDetailsRead {
  return ReturnProcessPickupDetailsReadToJSONTyped(json, false);
}

export function ReturnProcessPickupDetailsReadToJSONTyped(
  value?: ReturnProcessPickupDetailsRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    requested_pickup_date: value["requestedPickupDate"]
      .toISOString()
      .substring(0, 10),
    country: SupportedCountriesEnumToJSON(value["country"]),
    address: value["address"],
    post_code: value["postCode"],
    city: value["city"],
    phone_number: value["phoneNumber"],
    id: value["id"],
    set_by_user_id: value["setByUserId"],
    return_process_id: value["returnProcessId"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
  };
}
