/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
  PaymentServiceProviderToJSONTyped,
} from "./PaymentServiceProvider";
import type { PaymentStatus } from "./PaymentStatus";
import {
  PaymentStatusFromJSON,
  PaymentStatusFromJSONTyped,
  PaymentStatusToJSON,
  PaymentStatusToJSONTyped,
} from "./PaymentStatus";

/**
 *
 * @export
 * @interface PaymentDetailsRead
 */
export interface PaymentDetailsRead {
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  saleInvoiceId: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  dateCreated: Date;
  /**
   *
   * @type {number}
   * @memberof PaymentDetailsRead
   */
  amount: number;
  /**
   *
   * @type {Currency}
   * @memberof PaymentDetailsRead
   */
  currency: Currency;
  /**
   *
   * @type {PaymentStatus}
   * @memberof PaymentDetailsRead
   */
  status: PaymentStatus;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  failureReason?: string;
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof PaymentDetailsRead
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  externalPaymentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceType?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceBrand?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  sourceLabel?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  paymentConsentId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof PaymentDetailsRead
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof PaymentDetailsRead
   */
  link?: string;
}

/**
 * Check if a given object implements the PaymentDetailsRead interface.
 */
export function instanceOfPaymentDetailsRead(
  value: object,
): value is PaymentDetailsRead {
  if (!("saleInvoiceId" in value) || value["saleInvoiceId"] === undefined)
    return false;
  if (!("dateCreated" in value) || value["dateCreated"] === undefined)
    return false;
  if (!("amount" in value) || value["amount"] === undefined) return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("status" in value) || value["status"] === undefined) return false;
  if (
    !("paymentServiceProvider" in value) ||
    value["paymentServiceProvider"] === undefined
  )
    return false;
  return true;
}

export function PaymentDetailsReadFromJSON(json: any): PaymentDetailsRead {
  return PaymentDetailsReadFromJSONTyped(json, false);
}

export function PaymentDetailsReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentDetailsRead {
  if (json == null) {
    return json;
  }
  return {
    saleInvoiceId: json["sale_invoice_id"],
    dateCreated: new Date(json["date_created"]),
    amount: json["amount"],
    currency: CurrencyFromJSON(json["currency"]),
    status: PaymentStatusFromJSON(json["status"]),
    failureReason:
      json["failure_reason"] == null ? undefined : json["failure_reason"],
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    externalPaymentId:
      json["external_payment_id"] == null
        ? undefined
        : json["external_payment_id"],
    sourceType: json["source_type"] == null ? undefined : json["source_type"],
    sourceBrand:
      json["source_brand"] == null ? undefined : json["source_brand"],
    sourceLabel:
      json["source_label"] == null ? undefined : json["source_label"],
    paymentConsentId:
      json["payment_consent_id"] == null
        ? undefined
        : json["payment_consent_id"],
    id: json["id"] == null ? undefined : json["id"],
    createdAt:
      json["created_at"] == null ? undefined : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null ? undefined : new Date(json["updated_at"]),
    link: json["link"] == null ? undefined : json["link"],
  };
}

export function PaymentDetailsReadToJSON(json: any): PaymentDetailsRead {
  return PaymentDetailsReadToJSONTyped(json, false);
}

export function PaymentDetailsReadToJSONTyped(
  value?: PaymentDetailsRead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    sale_invoice_id: value["saleInvoiceId"],
    date_created: value["dateCreated"].toISOString().substring(0, 10),
    amount: value["amount"],
    currency: CurrencyToJSON(value["currency"]),
    status: PaymentStatusToJSON(value["status"]),
    failure_reason: value["failureReason"],
    payment_service_provider: PaymentServiceProviderToJSON(
      value["paymentServiceProvider"],
    ),
    external_payment_id: value["externalPaymentId"],
    source_type: value["sourceType"],
    source_brand: value["sourceBrand"],
    source_label: value["sourceLabel"],
    payment_consent_id: value["paymentConsentId"],
    id: value["id"],
    created_at:
      value["createdAt"] == null ? undefined : value["createdAt"].toISOString(),
    updated_at:
      value["updatedAt"] == null ? undefined : value["updatedAt"].toISOString(),
    link: value["link"],
  };
}
