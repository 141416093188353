/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { ReturnFee } from "./ReturnFee";
import {
  ReturnFeeFromJSON,
  ReturnFeeFromJSONTyped,
  ReturnFeeToJSON,
  ReturnFeeToJSONTyped,
} from "./ReturnFee";
import type { ReturnProcessAccessoryRedemptionCalculation } from "./ReturnProcessAccessoryRedemptionCalculation";
import {
  ReturnProcessAccessoryRedemptionCalculationFromJSON,
  ReturnProcessAccessoryRedemptionCalculationFromJSONTyped,
  ReturnProcessAccessoryRedemptionCalculationToJSON,
  ReturnProcessAccessoryRedemptionCalculationToJSONTyped,
} from "./ReturnProcessAccessoryRedemptionCalculation";

/**
 *
 * @export
 * @interface ReturnProcessPreliminaryData
 */
export interface ReturnProcessPreliminaryData {
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPreliminaryData
   */
  leaseEndDate: Date;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessPreliminaryData
   */
  minShippingFee: number;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessPreliminaryData
   */
  maxShippingFee: number;
  /**
   *
   * @type {ReturnFee}
   * @memberof ReturnProcessPreliminaryData
   */
  returnFeeCoverage: ReturnFee;
  /**
   *
   * @type {Array<ReturnProcessAccessoryRedemptionCalculation>}
   * @memberof ReturnProcessPreliminaryData
   */
  accessoryRedemptionCalculations: Array<ReturnProcessAccessoryRedemptionCalculation>;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessPreliminaryData
   */
  remainingMaintenanceBudget: number;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessPreliminaryData
   */
  overspentMaintenanceBudget: number;
}

/**
 * Check if a given object implements the ReturnProcessPreliminaryData interface.
 */
export function instanceOfReturnProcessPreliminaryData(
  value: object,
): value is ReturnProcessPreliminaryData {
  if (!("leaseEndDate" in value) || value["leaseEndDate"] === undefined)
    return false;
  if (!("minShippingFee" in value) || value["minShippingFee"] === undefined)
    return false;
  if (!("maxShippingFee" in value) || value["maxShippingFee"] === undefined)
    return false;
  if (
    !("returnFeeCoverage" in value) ||
    value["returnFeeCoverage"] === undefined
  )
    return false;
  if (
    !("accessoryRedemptionCalculations" in value) ||
    value["accessoryRedemptionCalculations"] === undefined
  )
    return false;
  if (
    !("remainingMaintenanceBudget" in value) ||
    value["remainingMaintenanceBudget"] === undefined
  )
    return false;
  if (
    !("overspentMaintenanceBudget" in value) ||
    value["overspentMaintenanceBudget"] === undefined
  )
    return false;
  return true;
}

export function ReturnProcessPreliminaryDataFromJSON(
  json: any,
): ReturnProcessPreliminaryData {
  return ReturnProcessPreliminaryDataFromJSONTyped(json, false);
}

export function ReturnProcessPreliminaryDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPreliminaryData {
  if (json == null) {
    return json;
  }
  return {
    leaseEndDate: new Date(json["lease_end_date"]),
    minShippingFee: json["min_shipping_fee"],
    maxShippingFee: json["max_shipping_fee"],
    returnFeeCoverage: ReturnFeeFromJSON(json["return_fee_coverage"]),
    accessoryRedemptionCalculations: (
      json["accessory_redemption_calculations"] as Array<any>
    ).map(ReturnProcessAccessoryRedemptionCalculationFromJSON),
    remainingMaintenanceBudget: json["remaining_maintenance_budget"],
    overspentMaintenanceBudget: json["overspent_maintenance_budget"],
  };
}

export function ReturnProcessPreliminaryDataToJSON(
  json: any,
): ReturnProcessPreliminaryData {
  return ReturnProcessPreliminaryDataToJSONTyped(json, false);
}

export function ReturnProcessPreliminaryDataToJSONTyped(
  value?: ReturnProcessPreliminaryData | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    lease_end_date: value["leaseEndDate"].toISOString().substring(0, 10),
    min_shipping_fee: value["minShippingFee"],
    max_shipping_fee: value["maxShippingFee"],
    return_fee_coverage: ReturnFeeToJSON(value["returnFeeCoverage"]),
    accessory_redemption_calculations: (
      value["accessoryRedemptionCalculations"] as Array<any>
    ).map(ReturnProcessAccessoryRedemptionCalculationToJSON),
    remaining_maintenance_budget: value["remainingMaintenanceBudget"],
    overspent_maintenance_budget: value["overspentMaintenanceBudget"],
  };
}
