/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { SaleInvoiceLineType } from "./SaleInvoiceLineType";
import {
  SaleInvoiceLineTypeFromJSON,
  SaleInvoiceLineTypeFromJSONTyped,
  SaleInvoiceLineTypeToJSON,
  SaleInvoiceLineTypeToJSONTyped,
} from "./SaleInvoiceLineType";

/**
 *
 * @export
 * @interface SaleInvoiceLineCreate
 */
export interface SaleInvoiceLineCreate {
  /**
   *
   * @type {SaleInvoiceLineType}
   * @memberof SaleInvoiceLineCreate
   */
  type: SaleInvoiceLineType;
  /**
   *
   * @type {string}
   * @memberof SaleInvoiceLineCreate
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof SaleInvoiceLineCreate
   */
  vatRate: number;
}

/**
 * Check if a given object implements the SaleInvoiceLineCreate interface.
 */
export function instanceOfSaleInvoiceLineCreate(
  value: object,
): value is SaleInvoiceLineCreate {
  if (!("type" in value) || value["type"] === undefined) return false;
  if (!("description" in value) || value["description"] === undefined)
    return false;
  if (!("price" in value) || value["price"] === undefined) return false;
  if (!("quantity" in value) || value["quantity"] === undefined) return false;
  if (!("vatRate" in value) || value["vatRate"] === undefined) return false;
  return true;
}

export function SaleInvoiceLineCreateFromJSON(
  json: any,
): SaleInvoiceLineCreate {
  return SaleInvoiceLineCreateFromJSONTyped(json, false);
}

export function SaleInvoiceLineCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SaleInvoiceLineCreate {
  if (json == null) {
    return json;
  }
  return {
    type: SaleInvoiceLineTypeFromJSON(json["type"]),
    description: json["description"],
    price: json["price"],
    quantity: json["quantity"],
    vatRate: json["vat_rate"],
  };
}

export function SaleInvoiceLineCreateToJSON(json: any): SaleInvoiceLineCreate {
  return SaleInvoiceLineCreateToJSONTyped(json, false);
}

export function SaleInvoiceLineCreateToJSONTyped(
  value?: SaleInvoiceLineCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    type: SaleInvoiceLineTypeToJSON(value["type"]),
    description: value["description"],
    price: value["price"],
    quantity: value["quantity"],
    vat_rate: value["vatRate"],
  };
}
