/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyBenefitDefinitionsDuplicateBenefitDefinition
 */
export interface BodyBenefitDefinitionsDuplicateBenefitDefinition {
  /**
   *
   * @type {Date}
   * @memberof BodyBenefitDefinitionsDuplicateBenefitDefinition
   */
  startDate: Date;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitDefinitionsDuplicateBenefitDefinition
   */
  copyBenefitActivations: boolean;
}

/**
 * Check if a given object implements the BodyBenefitDefinitionsDuplicateBenefitDefinition interface.
 */
export function instanceOfBodyBenefitDefinitionsDuplicateBenefitDefinition(
  value: object,
): value is BodyBenefitDefinitionsDuplicateBenefitDefinition {
  if (!("startDate" in value) || value["startDate"] === undefined) return false;
  if (
    !("copyBenefitActivations" in value) ||
    value["copyBenefitActivations"] === undefined
  )
    return false;
  return true;
}

export function BodyBenefitDefinitionsDuplicateBenefitDefinitionFromJSON(
  json: any,
): BodyBenefitDefinitionsDuplicateBenefitDefinition {
  return BodyBenefitDefinitionsDuplicateBenefitDefinitionFromJSONTyped(
    json,
    false,
  );
}

export function BodyBenefitDefinitionsDuplicateBenefitDefinitionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyBenefitDefinitionsDuplicateBenefitDefinition {
  if (json == null) {
    return json;
  }
  return {
    startDate: new Date(json["start_date"]),
    copyBenefitActivations: json["copy_benefit_activations"],
  };
}

export function BodyBenefitDefinitionsDuplicateBenefitDefinitionToJSON(
  json: any,
): BodyBenefitDefinitionsDuplicateBenefitDefinition {
  return BodyBenefitDefinitionsDuplicateBenefitDefinitionToJSONTyped(
    json,
    false,
  );
}

export function BodyBenefitDefinitionsDuplicateBenefitDefinitionToJSONTyped(
  value?: BodyBenefitDefinitionsDuplicateBenefitDefinition | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    start_date: value["startDate"].toISOString().substring(0, 10),
    copy_benefit_activations: value["copyBenefitActivations"],
  };
}
