/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { SupportedCountriesEnum } from "./SupportedCountriesEnum";
import {
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumFromJSONTyped,
  SupportedCountriesEnumToJSON,
  SupportedCountriesEnumToJSONTyped,
} from "./SupportedCountriesEnum";

/**
 *
 * @export
 * @interface ReturnProcessPickupDetailsWrite
 */
export interface ReturnProcessPickupDetailsWrite {
  /**
   *
   * @type {Date}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  requestedPickupDate: Date;
  /**
   *
   * @type {SupportedCountriesEnum}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  country: SupportedCountriesEnum;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  postCode: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessPickupDetailsWrite
   */
  phoneNumber: string;
}

/**
 * Check if a given object implements the ReturnProcessPickupDetailsWrite interface.
 */
export function instanceOfReturnProcessPickupDetailsWrite(
  value: object,
): value is ReturnProcessPickupDetailsWrite {
  if (
    !("requestedPickupDate" in value) ||
    value["requestedPickupDate"] === undefined
  )
    return false;
  if (!("country" in value) || value["country"] === undefined) return false;
  if (!("address" in value) || value["address"] === undefined) return false;
  if (!("postCode" in value) || value["postCode"] === undefined) return false;
  if (!("city" in value) || value["city"] === undefined) return false;
  if (!("phoneNumber" in value) || value["phoneNumber"] === undefined)
    return false;
  return true;
}

export function ReturnProcessPickupDetailsWriteFromJSON(
  json: any,
): ReturnProcessPickupDetailsWrite {
  return ReturnProcessPickupDetailsWriteFromJSONTyped(json, false);
}

export function ReturnProcessPickupDetailsWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessPickupDetailsWrite {
  if (json == null) {
    return json;
  }
  return {
    requestedPickupDate: new Date(json["requested_pickup_date"]),
    country: SupportedCountriesEnumFromJSON(json["country"]),
    address: json["address"],
    postCode: json["post_code"],
    city: json["city"],
    phoneNumber: json["phone_number"],
  };
}

export function ReturnProcessPickupDetailsWriteToJSON(
  json: any,
): ReturnProcessPickupDetailsWrite {
  return ReturnProcessPickupDetailsWriteToJSONTyped(json, false);
}

export function ReturnProcessPickupDetailsWriteToJSONTyped(
  value?: ReturnProcessPickupDetailsWrite | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    requested_pickup_date: value["requestedPickupDate"]
      .toISOString()
      .substring(0, 10),
    country: SupportedCountriesEnumToJSON(value["country"]),
    address: value["address"],
    post_code: value["postCode"],
    city: value["city"],
    phone_number: value["phoneNumber"],
  };
}
