import { useQuery } from "@tanstack/react-query";

import {
  BenefitApi,
  BenefitSearchBikeBenefitContractsRequest,
  BenefitSearchBikeBenefitOrdersRequest,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  PaginatedBikeBenefitContractOut,
  PaginatedBikeBenefitOrderOut,
  ResponseError,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";
import { UseQueryOptions } from "@vapaus/shared-api";

export const userBenefitApi = new BenefitApi(apiConfiguration);

export const useFindOrdersQuery = (
  params?: BenefitSearchBikeBenefitOrdersRequest,
) =>
  useQuery<PaginatedBikeBenefitOrderOut, ResponseError>({
    queryKey: ["orders", params],
    queryFn: () => userBenefitApi.benefitSearchBikeBenefitOrders(params),
  });

export const useGetOrder = (orderId: string) =>
  useQuery<BikeBenefitOrderRead, ResponseError>({
    queryKey: ["orders", orderId],
    queryFn: () =>
      userBenefitApi.benefitGetBikeBenefitOrder({ vapausCode: orderId }),
  });

export const useGetContract = (contractId?: string) =>
  useQuery<BikeBenefitContractRead, ResponseError>({
    queryKey: ["contracts", contractId],
    queryFn: () =>
      userBenefitApi.benefitGetBikeBenefitContract({
        contractId: contractId as string,
      }),
    enabled: Boolean(contractId),
  });

export const useSearchContracts = (
  requestParameters?: BenefitSearchBikeBenefitContractsRequest,
  options?: UseQueryOptions<PaginatedBikeBenefitContractOut>,
) =>
  useQuery<PaginatedBikeBenefitContractOut, ResponseError>(
    ["contract", requestParameters],
    () => userBenefitApi.benefitSearchBikeBenefitContracts(requestParameters),
    options,
  );
