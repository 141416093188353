/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedBikeBrandOut,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedBikeBrandOutFromJSON,
  PaginatedBikeBrandOutToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserShopBikeBrandsSearchBikeBrandsRequest {
  shopId?: string;
  active?: boolean;
  hasShops?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class UserShopBikeBrandsApi extends runtime.BaseAPI {
  /**
   * Search Bike Brands
   */
  async userShopBikeBrandsSearchBikeBrandsRaw(
    requestParameters: UserShopBikeBrandsSearchBikeBrandsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedBikeBrandOut>> {
    const queryParameters: any = {};

    if (requestParameters["shopId"] != null) {
      queryParameters["shop_id"] = requestParameters["shopId"];
    }

    if (requestParameters["active"] != null) {
      queryParameters["active"] = requestParameters["active"];
    }

    if (requestParameters["hasShops"] != null) {
      queryParameters["hasShops"] = requestParameters["hasShops"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/bike-brands`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedBikeBrandOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Bike Brands
   */
  async userShopBikeBrandsSearchBikeBrands(
    requestParameters: UserShopBikeBrandsSearchBikeBrandsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedBikeBrandOut> {
    const response = await this.userShopBikeBrandsSearchBikeBrandsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
