/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const PaymentStatus = {
  Registering: "registering",
  Pending: "pending",
  Unknown: "unknown",
  Charged: "charged",
  Failed: "failed",
  Cancelled: "cancelled",
} as const;
export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];

export function instanceOfPaymentStatus(value: any): boolean {
  for (const key in PaymentStatus) {
    if (Object.prototype.hasOwnProperty.call(PaymentStatus, key)) {
      if (PaymentStatus[key as keyof typeof PaymentStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentStatusFromJSON(json: any): PaymentStatus {
  return PaymentStatusFromJSONTyped(json, false);
}

export function PaymentStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentStatus {
  return json as PaymentStatus;
}

export function PaymentStatusToJSON(value?: PaymentStatus | null): any {
  return value as any;
}

export function PaymentStatusToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): PaymentStatus {
  return value as PaymentStatus;
}
