/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface BodyBenefitAcceptBikeBenefitOrder
 */
export interface BodyBenefitAcceptBikeBenefitOrder {
  /**
   *
   * @type {string}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  acceptedVersion: string;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  optionalCancellationCoverage: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BodyBenefitAcceptBikeBenefitOrder
   */
  isDownPaymentInsured?: boolean;
}

/**
 * Check if a given object implements the BodyBenefitAcceptBikeBenefitOrder interface.
 */
export function instanceOfBodyBenefitAcceptBikeBenefitOrder(
  value: object,
): value is BodyBenefitAcceptBikeBenefitOrder {
  if (!("acceptedVersion" in value) || value["acceptedVersion"] === undefined)
    return false;
  if (
    !("optionalCancellationCoverage" in value) ||
    value["optionalCancellationCoverage"] === undefined
  )
    return false;
  return true;
}

export function BodyBenefitAcceptBikeBenefitOrderFromJSON(
  json: any,
): BodyBenefitAcceptBikeBenefitOrder {
  return BodyBenefitAcceptBikeBenefitOrderFromJSONTyped(json, false);
}

export function BodyBenefitAcceptBikeBenefitOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyBenefitAcceptBikeBenefitOrder {
  if (json == null) {
    return json;
  }
  return {
    acceptedVersion: json["accepted_version"],
    optionalCancellationCoverage: json["optional_cancellation_coverage"],
    isDownPaymentInsured:
      json["is_down_payment_insured"] == null
        ? undefined
        : json["is_down_payment_insured"],
  };
}

export function BodyBenefitAcceptBikeBenefitOrderToJSON(
  json: any,
): BodyBenefitAcceptBikeBenefitOrder {
  return BodyBenefitAcceptBikeBenefitOrderToJSONTyped(json, false);
}

export function BodyBenefitAcceptBikeBenefitOrderToJSONTyped(
  value?: BodyBenefitAcceptBikeBenefitOrder | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    accepted_version: value["acceptedVersion"],
    optional_cancellation_coverage: value["optionalCancellationCoverage"],
    is_down_payment_insured: value["isDownPaymentInsured"],
  };
}
