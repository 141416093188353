/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserBenefitDefinitionAccessRequestCreate
 */
export interface UserBenefitDefinitionAccessRequestCreate {
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestCreate
   */
  businessId: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestCreate
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof UserBenefitDefinitionAccessRequestCreate
   */
  benefitDefinitionId?: string;
}

/**
 * Check if a given object implements the UserBenefitDefinitionAccessRequestCreate interface.
 */
export function instanceOfUserBenefitDefinitionAccessRequestCreate(
  value: object,
): value is UserBenefitDefinitionAccessRequestCreate {
  if (!("businessId" in value) || value["businessId"] === undefined)
    return false;
  if (!("userEmail" in value) || value["userEmail"] === undefined) return false;
  return true;
}

export function UserBenefitDefinitionAccessRequestCreateFromJSON(
  json: any,
): UserBenefitDefinitionAccessRequestCreate {
  return UserBenefitDefinitionAccessRequestCreateFromJSONTyped(json, false);
}

export function UserBenefitDefinitionAccessRequestCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserBenefitDefinitionAccessRequestCreate {
  if (json == null) {
    return json;
  }
  return {
    businessId: json["business_id"],
    userEmail: json["user_email"],
    benefitDefinitionId:
      json["benefit_definition_id"] == null
        ? undefined
        : json["benefit_definition_id"],
  };
}

export function UserBenefitDefinitionAccessRequestCreateToJSON(
  json: any,
): UserBenefitDefinitionAccessRequestCreate {
  return UserBenefitDefinitionAccessRequestCreateToJSONTyped(json, false);
}

export function UserBenefitDefinitionAccessRequestCreateToJSONTyped(
  value?: UserBenefitDefinitionAccessRequestCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    business_id: value["businessId"],
    user_email: value["userEmail"],
    benefit_definition_id: value["benefitDefinitionId"],
  };
}
