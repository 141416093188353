/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { PaymentConsent } from "./PaymentConsent";
import {
  PaymentConsentFromJSON,
  PaymentConsentFromJSONTyped,
  PaymentConsentToJSON,
  PaymentConsentToJSONTyped,
} from "./PaymentConsent";

/**
 *
 * @export
 * @interface PaginatedPaymentConsentOut
 */
export interface PaginatedPaymentConsentOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedPaymentConsentOut
   */
  total: number;
  /**
   *
   * @type {Array<PaymentConsent>}
   * @memberof PaginatedPaymentConsentOut
   */
  items: Array<PaymentConsent>;
}

/**
 * Check if a given object implements the PaginatedPaymentConsentOut interface.
 */
export function instanceOfPaginatedPaymentConsentOut(
  value: object,
): value is PaginatedPaymentConsentOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedPaymentConsentOutFromJSON(
  json: any,
): PaginatedPaymentConsentOut {
  return PaginatedPaymentConsentOutFromJSONTyped(json, false);
}

export function PaginatedPaymentConsentOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPaymentConsentOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(PaymentConsentFromJSON),
  };
}

export function PaginatedPaymentConsentOutToJSON(
  json: any,
): PaginatedPaymentConsentOut {
  return PaginatedPaymentConsentOutToJSONTyped(json, false);
}

export function PaginatedPaymentConsentOutToJSONTyped(
  value?: PaginatedPaymentConsentOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(PaymentConsentToJSON),
  };
}
