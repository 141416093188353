/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ReturnProcessAccessoryRedemptionCalculation
 */
export interface ReturnProcessAccessoryRedemptionCalculation {
  /**
   *
   * @type {string}
   * @memberof ReturnProcessAccessoryRedemptionCalculation
   */
  accessoryId: string;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessAccessoryRedemptionCalculation
   */
  purchasePrice: number;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessAccessoryRedemptionCalculation
   */
  redemptionPrice: number;
  /**
   *
   * @type {number}
   * @memberof ReturnProcessAccessoryRedemptionCalculation
   */
  discountPercentage: number;
}

/**
 * Check if a given object implements the ReturnProcessAccessoryRedemptionCalculation interface.
 */
export function instanceOfReturnProcessAccessoryRedemptionCalculation(
  value: object,
): value is ReturnProcessAccessoryRedemptionCalculation {
  if (!("accessoryId" in value) || value["accessoryId"] === undefined)
    return false;
  if (!("purchasePrice" in value) || value["purchasePrice"] === undefined)
    return false;
  if (!("redemptionPrice" in value) || value["redemptionPrice"] === undefined)
    return false;
  if (
    !("discountPercentage" in value) ||
    value["discountPercentage"] === undefined
  )
    return false;
  return true;
}

export function ReturnProcessAccessoryRedemptionCalculationFromJSON(
  json: any,
): ReturnProcessAccessoryRedemptionCalculation {
  return ReturnProcessAccessoryRedemptionCalculationFromJSONTyped(json, false);
}

export function ReturnProcessAccessoryRedemptionCalculationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessAccessoryRedemptionCalculation {
  if (json == null) {
    return json;
  }
  return {
    accessoryId: json["accessory_id"],
    purchasePrice: json["purchase_price"],
    redemptionPrice: json["redemption_price"],
    discountPercentage: json["discount_percentage"],
  };
}

export function ReturnProcessAccessoryRedemptionCalculationToJSON(
  json: any,
): ReturnProcessAccessoryRedemptionCalculation {
  return ReturnProcessAccessoryRedemptionCalculationToJSONTyped(json, false);
}

export function ReturnProcessAccessoryRedemptionCalculationToJSONTyped(
  value?: ReturnProcessAccessoryRedemptionCalculation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    accessory_id: value["accessoryId"],
    purchase_price: value["purchasePrice"],
    redemption_price: value["redemptionPrice"],
    discount_percentage: value["discountPercentage"],
  };
}
