/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionAccessRulesUserRead } from "./BenefitDefinitionAccessRulesUserRead";
import {
  BenefitDefinitionAccessRulesUserReadFromJSON,
  BenefitDefinitionAccessRulesUserReadFromJSONTyped,
  BenefitDefinitionAccessRulesUserReadToJSON,
  BenefitDefinitionAccessRulesUserReadToJSONTyped,
} from "./BenefitDefinitionAccessRulesUserRead";
import type { BenefitDefinitionCancellationCoverage } from "./BenefitDefinitionCancellationCoverage";
import {
  BenefitDefinitionCancellationCoverageFromJSON,
  BenefitDefinitionCancellationCoverageFromJSONTyped,
  BenefitDefinitionCancellationCoverageToJSON,
  BenefitDefinitionCancellationCoverageToJSONTyped,
} from "./BenefitDefinitionCancellationCoverage";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { OrganisationSummaryRead } from "./OrganisationSummaryRead";
import {
  OrganisationSummaryReadFromJSON,
  OrganisationSummaryReadFromJSONTyped,
  OrganisationSummaryReadToJSON,
  OrganisationSummaryReadToJSONTyped,
} from "./OrganisationSummaryRead";
import type { Plan } from "./Plan";
import {
  PlanFromJSON,
  PlanFromJSONTyped,
  PlanToJSON,
  PlanToJSONTyped,
} from "./Plan";

/**
 *
 * @export
 * @interface BenefitDefinitionCandidate
 */
export interface BenefitDefinitionCandidate {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCandidate
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCandidate
   */
  endDate: Date;
  /**
   *
   * @type {OrganisationSummaryRead}
   * @memberof BenefitDefinitionCandidate
   */
  organisation: OrganisationSummaryRead;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  active: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  toWhom?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  accessRulesUuid?: string;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isEmployeeNumberMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isCompanyMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isCostCenterMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isDownpaymentAllowed: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  minimumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumTotalPackagePrice: number;
  /**
   *
   * @type {Currency}
   * @memberof BenefitDefinitionCandidate
   */
  currency: Currency;
  /**
   *
   * @type {Plan}
   * @memberof BenefitDefinitionCandidate
   */
  plan: Plan;
  /**
   *
   * @type {BenefitDefinitionCancellationCoverage}
   * @memberof BenefitDefinitionCandidate
   */
  cancellationCoverage: BenefitDefinitionCancellationCoverage;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  individualOnetimeCancellationCoverage: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCandidate
   */
  isAvailable: boolean;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCandidate
   */
  currentBenefitDefinitionTermId?: string;
  /**
   *
   * @type {BenefitDefinitionAccessRulesUserRead}
   * @memberof BenefitDefinitionCandidate
   */
  accessRules?: BenefitDefinitionAccessRulesUserRead;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumTaxablePackagePrice?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  minimumMonthlyTaxableValue?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCandidate
   */
  maximumMonthlyTaxableValue?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BenefitDefinitionCandidate
   */
  fixedMonthlyTaxableValues?: Array<number>;
}

/**
 * Check if a given object implements the BenefitDefinitionCandidate interface.
 */
export function instanceOfBenefitDefinitionCandidate(
  value: object,
): value is BenefitDefinitionCandidate {
  if (!("id" in value) || value["id"] === undefined) return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("startDate" in value) || value["startDate"] === undefined) return false;
  if (!("endDate" in value) || value["endDate"] === undefined) return false;
  if (!("organisation" in value) || value["organisation"] === undefined)
    return false;
  if (!("active" in value) || value["active"] === undefined) return false;
  if (
    !("isEmployeeNumberMandatory" in value) ||
    value["isEmployeeNumberMandatory"] === undefined
  )
    return false;
  if (
    !("isCompanyMandatory" in value) ||
    value["isCompanyMandatory"] === undefined
  )
    return false;
  if (
    !("isCostCenterMandatory" in value) ||
    value["isCostCenterMandatory"] === undefined
  )
    return false;
  if (
    !("isDownpaymentAllowed" in value) ||
    value["isDownpaymentAllowed"] === undefined
  )
    return false;
  if (
    !("minimumLeasingPeriod" in value) ||
    value["minimumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("maximumLeasingPeriod" in value) ||
    value["maximumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("maximumTotalPackagePrice" in value) ||
    value["maximumTotalPackagePrice"] === undefined
  )
    return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("plan" in value) || value["plan"] === undefined) return false;
  if (
    !("cancellationCoverage" in value) ||
    value["cancellationCoverage"] === undefined
  )
    return false;
  if (
    !("individualOnetimeCancellationCoverage" in value) ||
    value["individualOnetimeCancellationCoverage"] === undefined
  )
    return false;
  if (!("isAvailable" in value) || value["isAvailable"] === undefined)
    return false;
  return true;
}

export function BenefitDefinitionCandidateFromJSON(
  json: any,
): BenefitDefinitionCandidate {
  return BenefitDefinitionCandidateFromJSONTyped(json, false);
}

export function BenefitDefinitionCandidateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCandidate {
  if (json == null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    organisation: OrganisationSummaryReadFromJSON(json["organisation"]),
    active: json["active"],
    toWhom: json["to_whom"] == null ? undefined : json["to_whom"],
    accessRulesUuid:
      json["access_rules_uuid"] == null ? undefined : json["access_rules_uuid"],
    isEmployeeNumberMandatory: json["is_employee_number_mandatory"],
    isCompanyMandatory: json["is_company_mandatory"],
    isCostCenterMandatory: json["is_cost_center_mandatory"],
    isDownpaymentAllowed: json["is_downpayment_allowed"],
    minimumLeasingPeriod: json["minimum_leasing_period"],
    maximumLeasingPeriod: json["maximum_leasing_period"],
    maximumTotalPackagePrice: json["maximum_total_package_price"],
    currency: CurrencyFromJSON(json["currency"]),
    plan: PlanFromJSON(json["plan"]),
    cancellationCoverage: BenefitDefinitionCancellationCoverageFromJSON(
      json["cancellation_coverage"],
    ),
    individualOnetimeCancellationCoverage:
      json["individual_onetime_cancellation_coverage"],
    isAvailable: json["is_available"],
    currentBenefitDefinitionTermId:
      json["current_benefit_definition_term_id"] == null
        ? undefined
        : json["current_benefit_definition_term_id"],
    accessRules:
      json["access_rules"] == null
        ? undefined
        : BenefitDefinitionAccessRulesUserReadFromJSON(json["access_rules"]),
    maximumTaxablePackagePrice:
      json["maximum_taxable_package_price"] == null
        ? undefined
        : json["maximum_taxable_package_price"],
    minimumMonthlyTaxableValue:
      json["minimum_monthly_taxable_value"] == null
        ? undefined
        : json["minimum_monthly_taxable_value"],
    maximumMonthlyTaxableValue:
      json["maximum_monthly_taxable_value"] == null
        ? undefined
        : json["maximum_monthly_taxable_value"],
    fixedMonthlyTaxableValues:
      json["fixed_monthly_taxable_values"] == null
        ? undefined
        : json["fixed_monthly_taxable_values"],
  };
}

export function BenefitDefinitionCandidateToJSON(
  json: any,
): BenefitDefinitionCandidate {
  return BenefitDefinitionCandidateToJSONTyped(json, false);
}

export function BenefitDefinitionCandidateToJSONTyped(
  value?: BenefitDefinitionCandidate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value["id"],
    name: value["name"],
    start_date: value["startDate"].toISOString().substring(0, 10),
    end_date: value["endDate"].toISOString().substring(0, 10),
    organisation: OrganisationSummaryReadToJSON(value["organisation"]),
    active: value["active"],
    to_whom: value["toWhom"],
    access_rules_uuid: value["accessRulesUuid"],
    is_employee_number_mandatory: value["isEmployeeNumberMandatory"],
    is_company_mandatory: value["isCompanyMandatory"],
    is_cost_center_mandatory: value["isCostCenterMandatory"],
    is_downpayment_allowed: value["isDownpaymentAllowed"],
    minimum_leasing_period: value["minimumLeasingPeriod"],
    maximum_leasing_period: value["maximumLeasingPeriod"],
    maximum_total_package_price: value["maximumTotalPackagePrice"],
    currency: CurrencyToJSON(value["currency"]),
    plan: PlanToJSON(value["plan"]),
    cancellation_coverage: BenefitDefinitionCancellationCoverageToJSON(
      value["cancellationCoverage"],
    ),
    individual_onetime_cancellation_coverage:
      value["individualOnetimeCancellationCoverage"],
    is_available: value["isAvailable"],
    current_benefit_definition_term_id: value["currentBenefitDefinitionTermId"],
    access_rules: BenefitDefinitionAccessRulesUserReadToJSON(
      value["accessRules"],
    ),
    maximum_taxable_package_price: value["maximumTaxablePackagePrice"],
    minimum_monthly_taxable_value: value["minimumMonthlyTaxableValue"],
    maximum_monthly_taxable_value: value["maximumMonthlyTaxableValue"],
    fixed_monthly_taxable_values: value["fixedMonthlyTaxableValues"],
  };
}
