/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BikeBenefitContractReturnOrRedemptionReason } from "./BikeBenefitContractReturnOrRedemptionReason";
import {
  BikeBenefitContractReturnOrRedemptionReasonFromJSON,
  BikeBenefitContractReturnOrRedemptionReasonFromJSONTyped,
  BikeBenefitContractReturnOrRedemptionReasonToJSON,
  BikeBenefitContractReturnOrRedemptionReasonToJSONTyped,
} from "./BikeBenefitContractReturnOrRedemptionReason";

/**
 *
 * @export
 * @interface RedemptionProcessCreate
 */
export interface RedemptionProcessCreate {
  /**
   *
   * @type {string}
   * @memberof RedemptionProcessCreate
   */
  bikeBenefitContractId: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionProcessCreate
   */
  plannedRedemptionDate: Date;
  /**
   *
   * @type {BikeBenefitContractReturnOrRedemptionReason}
   * @memberof RedemptionProcessCreate
   */
  redemptionReason: BikeBenefitContractReturnOrRedemptionReason;
}

/**
 * Check if a given object implements the RedemptionProcessCreate interface.
 */
export function instanceOfRedemptionProcessCreate(
  value: object,
): value is RedemptionProcessCreate {
  if (
    !("bikeBenefitContractId" in value) ||
    value["bikeBenefitContractId"] === undefined
  )
    return false;
  if (
    !("plannedRedemptionDate" in value) ||
    value["plannedRedemptionDate"] === undefined
  )
    return false;
  if (!("redemptionReason" in value) || value["redemptionReason"] === undefined)
    return false;
  return true;
}

export function RedemptionProcessCreateFromJSON(
  json: any,
): RedemptionProcessCreate {
  return RedemptionProcessCreateFromJSONTyped(json, false);
}

export function RedemptionProcessCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RedemptionProcessCreate {
  if (json == null) {
    return json;
  }
  return {
    bikeBenefitContractId: json["bike_benefit_contract_id"],
    plannedRedemptionDate: new Date(json["planned_redemption_date"]),
    redemptionReason: BikeBenefitContractReturnOrRedemptionReasonFromJSON(
      json["redemption_reason"],
    ),
  };
}

export function RedemptionProcessCreateToJSON(
  json: any,
): RedemptionProcessCreate {
  return RedemptionProcessCreateToJSONTyped(json, false);
}

export function RedemptionProcessCreateToJSONTyped(
  value?: RedemptionProcessCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    bike_benefit_contract_id: value["bikeBenefitContractId"],
    planned_redemption_date: value["plannedRedemptionDate"]
      .toISOString()
      .substring(0, 10),
    redemption_reason: BikeBenefitContractReturnOrRedemptionReasonToJSON(
      value["redemptionReason"],
    ),
  };
}
