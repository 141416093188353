/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Currency } from "./Currency";
import {
  CurrencyFromJSON,
  CurrencyFromJSONTyped,
  CurrencyToJSON,
  CurrencyToJSONTyped,
} from "./Currency";
import type { PaymentServiceProvider } from "./PaymentServiceProvider";
import {
  PaymentServiceProviderFromJSON,
  PaymentServiceProviderFromJSONTyped,
  PaymentServiceProviderToJSON,
  PaymentServiceProviderToJSONTyped,
} from "./PaymentServiceProvider";

/**
 *
 * @export
 * @interface BodyPaymentsCreatePaymentConsent
 */
export interface BodyPaymentsCreatePaymentConsent {
  /**
   *
   * @type {PaymentServiceProvider}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  paymentServiceProvider: PaymentServiceProvider;
  /**
   *
   * @type {Currency}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  currency: Currency;
  /**
   *
   * @type {string}
   * @memberof BodyPaymentsCreatePaymentConsent
   */
  returnUrl: string;
}

/**
 * Check if a given object implements the BodyPaymentsCreatePaymentConsent interface.
 */
export function instanceOfBodyPaymentsCreatePaymentConsent(
  value: object,
): value is BodyPaymentsCreatePaymentConsent {
  if (
    !("paymentServiceProvider" in value) ||
    value["paymentServiceProvider"] === undefined
  )
    return false;
  if (!("currency" in value) || value["currency"] === undefined) return false;
  if (!("returnUrl" in value) || value["returnUrl"] === undefined) return false;
  return true;
}

export function BodyPaymentsCreatePaymentConsentFromJSON(
  json: any,
): BodyPaymentsCreatePaymentConsent {
  return BodyPaymentsCreatePaymentConsentFromJSONTyped(json, false);
}

export function BodyPaymentsCreatePaymentConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BodyPaymentsCreatePaymentConsent {
  if (json == null) {
    return json;
  }
  return {
    paymentServiceProvider: PaymentServiceProviderFromJSON(
      json["payment_service_provider"],
    ),
    currency: CurrencyFromJSON(json["currency"]),
    returnUrl: json["return_url"],
  };
}

export function BodyPaymentsCreatePaymentConsentToJSON(
  json: any,
): BodyPaymentsCreatePaymentConsent {
  return BodyPaymentsCreatePaymentConsentToJSONTyped(json, false);
}

export function BodyPaymentsCreatePaymentConsentToJSONTyped(
  value?: BodyPaymentsCreatePaymentConsent | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    payment_service_provider: PaymentServiceProviderToJSON(
      value["paymentServiceProvider"],
    ),
    currency: CurrencyToJSON(value["currency"]),
    return_url: value["returnUrl"],
  };
}
