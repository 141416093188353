/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  OrderDirection,
  PaginatedUserShopOut,
  ShopLocation,
  ShopType,
  SupportedCountriesEnum,
  UserShopRead,
} from "../models/index";
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedUserShopOutFromJSON,
  PaginatedUserShopOutToJSON,
  ShopLocationFromJSON,
  ShopLocationToJSON,
  ShopTypeFromJSON,
  ShopTypeToJSON,
  SupportedCountriesEnumFromJSON,
  SupportedCountriesEnumToJSON,
  UserShopReadFromJSON,
  UserShopReadToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface UserShopGetShopRequest {
  shopId: string;
}

export interface UserShopSearchShopsRequest {
  country?: SupportedCountriesEnum;
  operationalCountry?: SupportedCountriesEnum;
  city?: string;
  shopType?: ShopType;
  brandIds?: Set<string>;
  isFeatured?: boolean;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

/**
 *
 */
export class UserShopApi extends runtime.BaseAPI {
  /**
   * Get Shop
   */
  async userShopGetShopRaw(
    requestParameters: UserShopGetShopRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserShopRead>> {
    if (requestParameters["shopId"] == null) {
      throw new runtime.RequiredError(
        "shopId",
        'Required parameter "shopId" was null or undefined when calling userShopGetShop().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/shops/{shop_id}`.replace(
          `{${"shop_id"}}`,
          encodeURIComponent(String(requestParameters["shopId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserShopReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Shop
   */
  async userShopGetShop(
    requestParameters: UserShopGetShopRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserShopRead> {
    const response = await this.userShopGetShopRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Shop Locations
   */
  async userShopSearchShopLocationsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ShopLocation>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/shops/locations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ShopLocationFromJSON),
    );
  }

  /**
   * Search Shop Locations
   */
  async userShopSearchShopLocations(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ShopLocation>> {
    const response = await this.userShopSearchShopLocationsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Search Shops
   */
  async userShopSearchShopsRaw(
    requestParameters: UserShopSearchShopsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedUserShopOut>> {
    const queryParameters: any = {};

    if (requestParameters["country"] != null) {
      queryParameters["country"] = requestParameters["country"];
    }

    if (requestParameters["operationalCountry"] != null) {
      queryParameters["operational_country"] =
        requestParameters["operationalCountry"];
    }

    if (requestParameters["city"] != null) {
      queryParameters["city"] = requestParameters["city"];
    }

    if (requestParameters["shopType"] != null) {
      queryParameters["shop_type"] = requestParameters["shopType"];
    }

    if (requestParameters["brandIds"] != null) {
      queryParameters["brand_ids"] = requestParameters["brandIds"];
    }

    if (requestParameters["isFeatured"] != null) {
      queryParameters["is_featured"] = requestParameters["isFeatured"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/shops`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedUserShopOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Shops
   */
  async userShopSearchShops(
    requestParameters: UserShopSearchShopsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedUserShopOut> {
    const response = await this.userShopSearchShopsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
