/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionCancellationCoverage } from "./BenefitDefinitionCancellationCoverage";
import {
  BenefitDefinitionCancellationCoverageFromJSON,
  BenefitDefinitionCancellationCoverageFromJSONTyped,
  BenefitDefinitionCancellationCoverageToJSON,
  BenefitDefinitionCancellationCoverageToJSONTyped,
} from "./BenefitDefinitionCancellationCoverage";
import type { FinancingMethods } from "./FinancingMethods";
import {
  FinancingMethodsFromJSON,
  FinancingMethodsFromJSONTyped,
  FinancingMethodsToJSON,
  FinancingMethodsToJSONTyped,
} from "./FinancingMethods";
import type { Payer } from "./Payer";
import {
  PayerFromJSON,
  PayerFromJSONTyped,
  PayerToJSON,
  PayerToJSONTyped,
} from "./Payer";
import type { Plan } from "./Plan";
import {
  PlanFromJSON,
  PlanFromJSONTyped,
  PlanToJSON,
  PlanToJSONTyped,
} from "./Plan";
import type { ReturnFee } from "./ReturnFee";
import {
  ReturnFeeFromJSON,
  ReturnFeeFromJSONTyped,
  ReturnFeeToJSON,
  ReturnFeeToJSONTyped,
} from "./ReturnFee";

/**
 *
 * @export
 * @interface BenefitDefinitionCreate
 */
export interface BenefitDefinitionCreate {
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  organisationId: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  organisationBillingDetailsId: string;
  /**
   *
   * @type {Plan}
   * @memberof BenefitDefinitionCreate
   */
  plan: Plan;
  /**
   *
   * @type {Payer}
   * @memberof BenefitDefinitionCreate
   */
  excessMonthlyPayer?: Payer;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  endDate: Date;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  orderProcessingGracePeriodDays?: number;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  toWhom?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  what?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  how?: string;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumLeasingPeriod: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumTaxablePackagePrice?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumMonthlyTaxableValue: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumMonthlyTaxableValue: number;
  /**
   *
   * @type {Array<number>}
   * @memberof BenefitDefinitionCreate
   */
  fixedMonthlyTaxableValues?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  defaultResidualRatio: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  defaultMonthlyDecreaseInResidualValue: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isEndOfLeaseRedemptionPriceDiscounted?: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  fixedEndOfLeaseRedemptionPercentage?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  minimumMaintenanceServiceMonthly: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumMaintenanceServiceMonthly: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  maximumTotalPackagePrice: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isDownpaymentAllowed: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  standardCoverageInsuranceRate: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  fullCoverageInsuranceRate: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  insuranceDeductible: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netCompanyMonthlyCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netIndividualMonthlyCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netIndividualOnetimeCancellationCoverage: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netReturnFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netRedemptionFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netDeliveryFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netCustomerServiceMonthlyFee: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  netLegacyWarrantyMonthlyFee: number;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isEmployeeNumberMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isCompanyMandatory: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BenefitDefinitionCreate
   */
  isCostCenterMandatory: boolean;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  billingFrequency?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  paymentTerms?: number;
  /**
   * Netvisor code 2
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  serviceFeeProductCode?: string;
  /**
   * Netvisor code 1
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  bikeContractProductCode?: string;
  /**
   *
   * @type {FinancingMethods}
   * @memberof BenefitDefinitionCreate
   */
  financingMethod?: FinancingMethods;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  financingApplicationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionNumber?: string;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionExpirationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionMaxLeasingPeriod?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionTotalAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionUsedAmount?: number;
  /**
   *
   * @type {number}
   * @memberof BenefitDefinitionCreate
   */
  financingDecisionRemainingAmount?: number;
  /**
   *
   * @type {string}
   * @memberof BenefitDefinitionCreate
   */
  accessRulesUuid?: string;
  /**
   *
   * @type {BenefitDefinitionCancellationCoverage}
   * @memberof BenefitDefinitionCreate
   */
  cancellationCoverage: BenefitDefinitionCancellationCoverage;
  /**
   *
   * @type {ReturnFee}
   * @memberof BenefitDefinitionCreate
   */
  returnFeeCoverage: ReturnFee;
}

/**
 * Check if a given object implements the BenefitDefinitionCreate interface.
 */
export function instanceOfBenefitDefinitionCreate(
  value: object,
): value is BenefitDefinitionCreate {
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("organisationId" in value) || value["organisationId"] === undefined)
    return false;
  if (
    !("organisationBillingDetailsId" in value) ||
    value["organisationBillingDetailsId"] === undefined
  )
    return false;
  if (!("plan" in value) || value["plan"] === undefined) return false;
  if (!("startDate" in value) || value["startDate"] === undefined) return false;
  if (!("endDate" in value) || value["endDate"] === undefined) return false;
  if (
    !("minimumLeasingPeriod" in value) ||
    value["minimumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("maximumLeasingPeriod" in value) ||
    value["maximumLeasingPeriod"] === undefined
  )
    return false;
  if (
    !("minimumMonthlyTaxableValue" in value) ||
    value["minimumMonthlyTaxableValue"] === undefined
  )
    return false;
  if (
    !("maximumMonthlyTaxableValue" in value) ||
    value["maximumMonthlyTaxableValue"] === undefined
  )
    return false;
  if (
    !("defaultResidualRatio" in value) ||
    value["defaultResidualRatio"] === undefined
  )
    return false;
  if (
    !("defaultMonthlyDecreaseInResidualValue" in value) ||
    value["defaultMonthlyDecreaseInResidualValue"] === undefined
  )
    return false;
  if (
    !("minimumMaintenanceServiceMonthly" in value) ||
    value["minimumMaintenanceServiceMonthly"] === undefined
  )
    return false;
  if (
    !("maximumMaintenanceServiceMonthly" in value) ||
    value["maximumMaintenanceServiceMonthly"] === undefined
  )
    return false;
  if (
    !("maximumTotalPackagePrice" in value) ||
    value["maximumTotalPackagePrice"] === undefined
  )
    return false;
  if (
    !("isDownpaymentAllowed" in value) ||
    value["isDownpaymentAllowed"] === undefined
  )
    return false;
  if (
    !("standardCoverageInsuranceRate" in value) ||
    value["standardCoverageInsuranceRate"] === undefined
  )
    return false;
  if (
    !("fullCoverageInsuranceRate" in value) ||
    value["fullCoverageInsuranceRate"] === undefined
  )
    return false;
  if (
    !("insuranceDeductible" in value) ||
    value["insuranceDeductible"] === undefined
  )
    return false;
  if (
    !("netCompanyMonthlyCancellationCoverage" in value) ||
    value["netCompanyMonthlyCancellationCoverage"] === undefined
  )
    return false;
  if (
    !("netIndividualMonthlyCancellationCoverage" in value) ||
    value["netIndividualMonthlyCancellationCoverage"] === undefined
  )
    return false;
  if (
    !("netIndividualOnetimeCancellationCoverage" in value) ||
    value["netIndividualOnetimeCancellationCoverage"] === undefined
  )
    return false;
  if (!("netReturnFee" in value) || value["netReturnFee"] === undefined)
    return false;
  if (!("netRedemptionFee" in value) || value["netRedemptionFee"] === undefined)
    return false;
  if (!("netDeliveryFee" in value) || value["netDeliveryFee"] === undefined)
    return false;
  if (
    !("netCustomerServiceMonthlyFee" in value) ||
    value["netCustomerServiceMonthlyFee"] === undefined
  )
    return false;
  if (
    !("netLegacyWarrantyMonthlyFee" in value) ||
    value["netLegacyWarrantyMonthlyFee"] === undefined
  )
    return false;
  if (
    !("isEmployeeNumberMandatory" in value) ||
    value["isEmployeeNumberMandatory"] === undefined
  )
    return false;
  if (
    !("isCompanyMandatory" in value) ||
    value["isCompanyMandatory"] === undefined
  )
    return false;
  if (
    !("isCostCenterMandatory" in value) ||
    value["isCostCenterMandatory"] === undefined
  )
    return false;
  if (
    !("cancellationCoverage" in value) ||
    value["cancellationCoverage"] === undefined
  )
    return false;
  if (
    !("returnFeeCoverage" in value) ||
    value["returnFeeCoverage"] === undefined
  )
    return false;
  return true;
}

export function BenefitDefinitionCreateFromJSON(
  json: any,
): BenefitDefinitionCreate {
  return BenefitDefinitionCreateFromJSONTyped(json, false);
}

export function BenefitDefinitionCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BenefitDefinitionCreate {
  if (json == null) {
    return json;
  }
  return {
    name: json["name"],
    organisationId: json["organisation_id"],
    organisationBillingDetailsId: json["organisation_billing_details_id"],
    plan: PlanFromJSON(json["plan"]),
    excessMonthlyPayer:
      json["excess_monthly_payer"] == null
        ? undefined
        : PayerFromJSON(json["excess_monthly_payer"]),
    description: json["description"] == null ? undefined : json["description"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    orderProcessingGracePeriodDays:
      json["order_processing_grace_period_days"] == null
        ? undefined
        : json["order_processing_grace_period_days"],
    toWhom: json["to_whom"] == null ? undefined : json["to_whom"],
    what: json["what"] == null ? undefined : json["what"],
    how: json["how"] == null ? undefined : json["how"],
    minimumLeasingPeriod: json["minimum_leasing_period"],
    maximumLeasingPeriod: json["maximum_leasing_period"],
    maximumTaxablePackagePrice:
      json["maximum_taxable_package_price"] == null
        ? undefined
        : json["maximum_taxable_package_price"],
    minimumMonthlyTaxableValue: json["minimum_monthly_taxable_value"],
    maximumMonthlyTaxableValue: json["maximum_monthly_taxable_value"],
    fixedMonthlyTaxableValues:
      json["fixed_monthly_taxable_values"] == null
        ? undefined
        : json["fixed_monthly_taxable_values"],
    defaultResidualRatio: json["default_residual_ratio"],
    defaultMonthlyDecreaseInResidualValue:
      json["default_monthly_decrease_in_residual_value"],
    isEndOfLeaseRedemptionPriceDiscounted:
      json["is_end_of_lease_redemption_price_discounted"] == null
        ? undefined
        : json["is_end_of_lease_redemption_price_discounted"],
    fixedEndOfLeaseRedemptionPercentage:
      json["fixed_end_of_lease_redemption_percentage"] == null
        ? undefined
        : json["fixed_end_of_lease_redemption_percentage"],
    minimumMaintenanceServiceMonthly:
      json["minimum_maintenance_service_monthly"],
    maximumMaintenanceServiceMonthly:
      json["maximum_maintenance_service_monthly"],
    maximumTotalPackagePrice: json["maximum_total_package_price"],
    isDownpaymentAllowed: json["is_downpayment_allowed"],
    standardCoverageInsuranceRate: json["standard_coverage_insurance_rate"],
    fullCoverageInsuranceRate: json["full_coverage_insurance_rate"],
    insuranceDeductible: json["insurance_deductible"],
    netCompanyMonthlyCancellationCoverage:
      json["net_company_monthly_cancellation_coverage"],
    netIndividualMonthlyCancellationCoverage:
      json["net_individual_monthly_cancellation_coverage"],
    netIndividualOnetimeCancellationCoverage:
      json["net_individual_onetime_cancellation_coverage"],
    netReturnFee: json["net_return_fee"],
    netRedemptionFee: json["net_redemption_fee"],
    netDeliveryFee: json["net_delivery_fee"],
    netCustomerServiceMonthlyFee: json["net_customer_service_monthly_fee"],
    netLegacyWarrantyMonthlyFee: json["net_legacy_warranty_monthly_fee"],
    isEmployeeNumberMandatory: json["is_employee_number_mandatory"],
    isCompanyMandatory: json["is_company_mandatory"],
    isCostCenterMandatory: json["is_cost_center_mandatory"],
    billingFrequency:
      json["billing_frequency"] == null ? undefined : json["billing_frequency"],
    paymentTerms:
      json["payment_terms"] == null ? undefined : json["payment_terms"],
    serviceFeeProductCode:
      json["service_fee_product_code"] == null
        ? undefined
        : json["service_fee_product_code"],
    bikeContractProductCode:
      json["bike_contract_product_code"] == null
        ? undefined
        : json["bike_contract_product_code"],
    financingMethod:
      json["financing_method"] == null
        ? undefined
        : FinancingMethodsFromJSON(json["financing_method"]),
    financingApplicationNumber:
      json["financing_application_number"] == null
        ? undefined
        : json["financing_application_number"],
    financingDecisionNumber:
      json["financing_decision_number"] == null
        ? undefined
        : json["financing_decision_number"],
    financingDecisionDate:
      json["financing_decision_date"] == null
        ? undefined
        : new Date(json["financing_decision_date"]),
    financingDecisionExpirationDate:
      json["financing_decision_expiration_date"] == null
        ? undefined
        : new Date(json["financing_decision_expiration_date"]),
    financingDecisionMaxLeasingPeriod:
      json["financing_decision_max_leasing_period"] == null
        ? undefined
        : json["financing_decision_max_leasing_period"],
    financingDecisionTotalAmount:
      json["financing_decision_total_amount"] == null
        ? undefined
        : json["financing_decision_total_amount"],
    financingDecisionUsedAmount:
      json["financing_decision_used_amount"] == null
        ? undefined
        : json["financing_decision_used_amount"],
    financingDecisionRemainingAmount:
      json["financing_decision_remaining_amount"] == null
        ? undefined
        : json["financing_decision_remaining_amount"],
    accessRulesUuid:
      json["access_rules_uuid"] == null ? undefined : json["access_rules_uuid"],
    cancellationCoverage: BenefitDefinitionCancellationCoverageFromJSON(
      json["cancellation_coverage"],
    ),
    returnFeeCoverage: ReturnFeeFromJSON(json["return_fee_coverage"]),
  };
}

export function BenefitDefinitionCreateToJSON(
  json: any,
): BenefitDefinitionCreate {
  return BenefitDefinitionCreateToJSONTyped(json, false);
}

export function BenefitDefinitionCreateToJSONTyped(
  value?: BenefitDefinitionCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value["name"],
    organisation_id: value["organisationId"],
    organisation_billing_details_id: value["organisationBillingDetailsId"],
    plan: PlanToJSON(value["plan"]),
    excess_monthly_payer: PayerToJSON(value["excessMonthlyPayer"]),
    description: value["description"],
    start_date: value["startDate"].toISOString().substring(0, 10),
    end_date: value["endDate"].toISOString().substring(0, 10),
    order_processing_grace_period_days: value["orderProcessingGracePeriodDays"],
    to_whom: value["toWhom"],
    what: value["what"],
    how: value["how"],
    minimum_leasing_period: value["minimumLeasingPeriod"],
    maximum_leasing_period: value["maximumLeasingPeriod"],
    maximum_taxable_package_price: value["maximumTaxablePackagePrice"],
    minimum_monthly_taxable_value: value["minimumMonthlyTaxableValue"],
    maximum_monthly_taxable_value: value["maximumMonthlyTaxableValue"],
    fixed_monthly_taxable_values: value["fixedMonthlyTaxableValues"],
    default_residual_ratio: value["defaultResidualRatio"],
    default_monthly_decrease_in_residual_value:
      value["defaultMonthlyDecreaseInResidualValue"],
    is_end_of_lease_redemption_price_discounted:
      value["isEndOfLeaseRedemptionPriceDiscounted"],
    fixed_end_of_lease_redemption_percentage:
      value["fixedEndOfLeaseRedemptionPercentage"],
    minimum_maintenance_service_monthly:
      value["minimumMaintenanceServiceMonthly"],
    maximum_maintenance_service_monthly:
      value["maximumMaintenanceServiceMonthly"],
    maximum_total_package_price: value["maximumTotalPackagePrice"],
    is_downpayment_allowed: value["isDownpaymentAllowed"],
    standard_coverage_insurance_rate: value["standardCoverageInsuranceRate"],
    full_coverage_insurance_rate: value["fullCoverageInsuranceRate"],
    insurance_deductible: value["insuranceDeductible"],
    net_company_monthly_cancellation_coverage:
      value["netCompanyMonthlyCancellationCoverage"],
    net_individual_monthly_cancellation_coverage:
      value["netIndividualMonthlyCancellationCoverage"],
    net_individual_onetime_cancellation_coverage:
      value["netIndividualOnetimeCancellationCoverage"],
    net_return_fee: value["netReturnFee"],
    net_redemption_fee: value["netRedemptionFee"],
    net_delivery_fee: value["netDeliveryFee"],
    net_customer_service_monthly_fee: value["netCustomerServiceMonthlyFee"],
    net_legacy_warranty_monthly_fee: value["netLegacyWarrantyMonthlyFee"],
    is_employee_number_mandatory: value["isEmployeeNumberMandatory"],
    is_company_mandatory: value["isCompanyMandatory"],
    is_cost_center_mandatory: value["isCostCenterMandatory"],
    billing_frequency: value["billingFrequency"],
    payment_terms: value["paymentTerms"],
    service_fee_product_code: value["serviceFeeProductCode"],
    bike_contract_product_code: value["bikeContractProductCode"],
    financing_method: FinancingMethodsToJSON(value["financingMethod"]),
    financing_application_number: value["financingApplicationNumber"],
    financing_decision_number: value["financingDecisionNumber"],
    financing_decision_date:
      value["financingDecisionDate"] == null
        ? undefined
        : value["financingDecisionDate"].toISOString().substring(0, 10),
    financing_decision_expiration_date:
      value["financingDecisionExpirationDate"] == null
        ? undefined
        : value["financingDecisionExpirationDate"]
            .toISOString()
            .substring(0, 10),
    financing_decision_max_leasing_period:
      value["financingDecisionMaxLeasingPeriod"],
    financing_decision_total_amount: value["financingDecisionTotalAmount"],
    financing_decision_used_amount: value["financingDecisionUsedAmount"],
    financing_decision_remaining_amount:
      value["financingDecisionRemainingAmount"],
    access_rules_uuid: value["accessRulesUuid"],
    cancellation_coverage: BenefitDefinitionCancellationCoverageToJSON(
      value["cancellationCoverage"],
    ),
    return_fee_coverage: ReturnFeeToJSON(value["returnFeeCoverage"]),
  };
}
