/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { BenefitDefinitionCandidate } from "./BenefitDefinitionCandidate";
import {
  BenefitDefinitionCandidateFromJSON,
  BenefitDefinitionCandidateFromJSONTyped,
  BenefitDefinitionCandidateToJSON,
  BenefitDefinitionCandidateToJSONTyped,
} from "./BenefitDefinitionCandidate";

/**
 *
 * @export
 * @interface PaginatedBenefitDefinitionCandidateOut
 */
export interface PaginatedBenefitDefinitionCandidateOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedBenefitDefinitionCandidateOut
   */
  total: number;
  /**
   *
   * @type {Array<BenefitDefinitionCandidate>}
   * @memberof PaginatedBenefitDefinitionCandidateOut
   */
  items: Array<BenefitDefinitionCandidate>;
}

/**
 * Check if a given object implements the PaginatedBenefitDefinitionCandidateOut interface.
 */
export function instanceOfPaginatedBenefitDefinitionCandidateOut(
  value: object,
): value is PaginatedBenefitDefinitionCandidateOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedBenefitDefinitionCandidateOutFromJSON(
  json: any,
): PaginatedBenefitDefinitionCandidateOut {
  return PaginatedBenefitDefinitionCandidateOutFromJSONTyped(json, false);
}

export function PaginatedBenefitDefinitionCandidateOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedBenefitDefinitionCandidateOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(
      BenefitDefinitionCandidateFromJSON,
    ),
  };
}

export function PaginatedBenefitDefinitionCandidateOutToJSON(
  json: any,
): PaginatedBenefitDefinitionCandidateOut {
  return PaginatedBenefitDefinitionCandidateOutToJSONTyped(json, false);
}

export function PaginatedBenefitDefinitionCandidateOutToJSONTyped(
  value?: PaginatedBenefitDefinitionCandidateOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(BenefitDefinitionCandidateToJSON),
  };
}
