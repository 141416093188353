/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const OrderDirection = {
  Asc: "asc",
  Desc: "desc",
} as const;
export type OrderDirection =
  (typeof OrderDirection)[keyof typeof OrderDirection];

export function instanceOfOrderDirection(value: any): boolean {
  for (const key in OrderDirection) {
    if (Object.prototype.hasOwnProperty.call(OrderDirection, key)) {
      if (OrderDirection[key as keyof typeof OrderDirection] === value) {
        return true;
      }
    }
  }
  return false;
}

export function OrderDirectionFromJSON(json: any): OrderDirection {
  return OrderDirectionFromJSONTyped(json, false);
}

export function OrderDirectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrderDirection {
  return json as OrderDirection;
}

export function OrderDirectionToJSON(value?: OrderDirection | null): any {
  return value as any;
}

export function OrderDirectionToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): OrderDirection {
  return value as OrderDirection;
}
