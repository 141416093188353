/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  BodyPaymentsCreatePaymentConsent,
  ErrorResponse,
  ErrorWithCodeResponse,
  HTTPValidationError,
  Msg,
  OrderDirection,
  PaginatedPaymentConsentOut,
  PaginatedPaymentOut,
  PaymentConsentDetailsRead,
  PaymentConsentStatus,
} from "../models/index";
import {
  BodyPaymentsCreatePaymentConsentFromJSON,
  BodyPaymentsCreatePaymentConsentToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ErrorWithCodeResponseFromJSON,
  ErrorWithCodeResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MsgFromJSON,
  MsgToJSON,
  OrderDirectionFromJSON,
  OrderDirectionToJSON,
  PaginatedPaymentConsentOutFromJSON,
  PaginatedPaymentConsentOutToJSON,
  PaginatedPaymentOutFromJSON,
  PaginatedPaymentOutToJSON,
  PaymentConsentDetailsReadFromJSON,
  PaymentConsentDetailsReadToJSON,
  PaymentConsentStatusFromJSON,
  PaymentConsentStatusToJSON,
} from "../models/index";
import * as runtime from "../runtime";

export interface PaymentsCancelPaymentConsentRequest {
  paymentConsentId: string;
}

export interface PaymentsCreatePaymentConsentRequest {
  bodyPaymentsCreatePaymentConsent: BodyPaymentsCreatePaymentConsent;
}

export interface PaymentsGetPaymentConsentRequest {
  paymentConsentId: string;
}

export interface PaymentsSearchPaymentConsentsRequest {
  status?: Set<PaymentConsentStatus>;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface PaymentsSearchPaymentConsents0Request {
  userId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface PaymentsSearchPaymentsRequest {
  saleInvoiceId?: string;
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit?: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
}

export interface PaymentsVismaPayCallbackRequest {
  rETURNCODE?: string;
  oRDERNUMBER?: string;
  sETTLED?: string;
  iNCIDENTID?: string;
  aUTHCODE?: string;
}

/**
 *
 */
export class PaymentsApi extends runtime.BaseAPI {
  /**
   * Cancel Payment Consent
   */
  async paymentsCancelPaymentConsentRaw(
    requestParameters: PaymentsCancelPaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentConsentDetailsRead>> {
    if (requestParameters["paymentConsentId"] == null) {
      throw new runtime.RequiredError(
        "paymentConsentId",
        'Required parameter "paymentConsentId" was null or undefined when calling paymentsCancelPaymentConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payments/consents/{payment_consent_id}/cancel`.replace(
          `{${"payment_consent_id"}}`,
          encodeURIComponent(String(requestParameters["paymentConsentId"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentConsentDetailsReadFromJSON(jsonValue),
    );
  }

  /**
   * Cancel Payment Consent
   */
  async paymentsCancelPaymentConsent(
    requestParameters: PaymentsCancelPaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentConsentDetailsRead> {
    const response = await this.paymentsCancelPaymentConsentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Create Payment Consent
   */
  async paymentsCreatePaymentConsentRaw(
    requestParameters: PaymentsCreatePaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentConsentDetailsRead>> {
    if (requestParameters["bodyPaymentsCreatePaymentConsent"] == null) {
      throw new runtime.RequiredError(
        "bodyPaymentsCreatePaymentConsent",
        'Required parameter "bodyPaymentsCreatePaymentConsent" was null or undefined when calling paymentsCreatePaymentConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payments/consents`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BodyPaymentsCreatePaymentConsentToJSON(
          requestParameters["bodyPaymentsCreatePaymentConsent"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentConsentDetailsReadFromJSON(jsonValue),
    );
  }

  /**
   * Create Payment Consent
   */
  async paymentsCreatePaymentConsent(
    requestParameters: PaymentsCreatePaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentConsentDetailsRead> {
    const response = await this.paymentsCreatePaymentConsentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get Payment Consent
   */
  async paymentsGetPaymentConsentRaw(
    requestParameters: PaymentsGetPaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentConsentDetailsRead>> {
    if (requestParameters["paymentConsentId"] == null) {
      throw new runtime.RequiredError(
        "paymentConsentId",
        'Required parameter "paymentConsentId" was null or undefined when calling paymentsGetPaymentConsent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payments/consents/{payment_consent_id}`.replace(
          `{${"payment_consent_id"}}`,
          encodeURIComponent(String(requestParameters["paymentConsentId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentConsentDetailsReadFromJSON(jsonValue),
    );
  }

  /**
   * Get Payment Consent
   */
  async paymentsGetPaymentConsent(
    requestParameters: PaymentsGetPaymentConsentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentConsentDetailsRead> {
    const response = await this.paymentsGetPaymentConsentRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Payment Consents
   */
  async paymentsSearchPaymentConsentsRaw(
    requestParameters: PaymentsSearchPaymentConsentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPaymentConsentOut>> {
    const queryParameters: any = {};

    if (requestParameters["status"] != null) {
      queryParameters["status"] = requestParameters["status"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payments/consents`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPaymentConsentOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Payment Consents
   */
  async paymentsSearchPaymentConsents(
    requestParameters: PaymentsSearchPaymentConsentsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPaymentConsentOut> {
    const response = await this.paymentsSearchPaymentConsentsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Payment Consents
   */
  async paymentsSearchPaymentConsents_1Raw(
    requestParameters: PaymentsSearchPaymentConsents0Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPaymentConsentOut>> {
    const queryParameters: any = {};

    if (requestParameters["userId"] != null) {
      queryParameters["user_id"] = requestParameters["userId"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/admin-api/payments/consents`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPaymentConsentOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Payment Consents
   */
  async paymentsSearchPaymentConsents_1(
    requestParameters: PaymentsSearchPaymentConsents0Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPaymentConsentOut> {
    const response = await this.paymentsSearchPaymentConsents_1Raw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Search Payments
   */
  async paymentsSearchPaymentsRaw(
    requestParameters: PaymentsSearchPaymentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPaymentOut>> {
    const queryParameters: any = {};

    if (requestParameters["saleInvoiceId"] != null) {
      queryParameters["sale_invoice_id"] = requestParameters["saleInvoiceId"];
    }

    if (requestParameters["q"] != null) {
      queryParameters["q"] = requestParameters["q"];
    }

    if (requestParameters["ids"] != null) {
      queryParameters["ids"] = requestParameters["ids"];
    }

    if (requestParameters["skip"] != null) {
      queryParameters["skip"] = requestParameters["skip"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["sortField"] != null) {
      queryParameters["sort_field"] = requestParameters["sortField"];
    }

    if (requestParameters["sortDirection"] != null) {
      queryParameters["sort_direction"] = requestParameters["sortDirection"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters["xFilterIds"] != null) {
      headerParameters["X-Filter-Ids"] = String(
        requestParameters["xFilterIds"],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payments`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPaymentOutFromJSON(jsonValue),
    );
  }

  /**
   * Search Payments
   */
  async paymentsSearchPayments(
    requestParameters: PaymentsSearchPaymentsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPaymentOut> {
    const response = await this.paymentsSearchPaymentsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Visma Pay Callback
   */
  async paymentsVismaPayCallbackRaw(
    requestParameters: PaymentsVismaPayCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Msg>> {
    const queryParameters: any = {};

    if (requestParameters["rETURNCODE"] != null) {
      queryParameters["RETURN_CODE"] = requestParameters["rETURNCODE"];
    }

    if (requestParameters["oRDERNUMBER"] != null) {
      queryParameters["ORDER_NUMBER"] = requestParameters["oRDERNUMBER"];
    }

    if (requestParameters["sETTLED"] != null) {
      queryParameters["SETTLED"] = requestParameters["sETTLED"];
    }

    if (requestParameters["iNCIDENTID"] != null) {
      queryParameters["INCIDENT_ID"] = requestParameters["iNCIDENTID"];
    }

    if (requestParameters["aUTHCODE"] != null) {
      queryParameters["AUTHCODE"] = requestParameters["aUTHCODE"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters["Authorization"] = await this.configuration.accessToken(
        "OAuth2PasswordBearer",
        [],
      );
    }

    const response = await this.request(
      {
        path: `/api/payment-services/visma-pay-callback`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MsgFromJSON(jsonValue),
    );
  }

  /**
   * Visma Pay Callback
   */
  async paymentsVismaPayCallback(
    requestParameters: PaymentsVismaPayCallbackRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Msg> {
    const response = await this.paymentsVismaPayCallbackRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
