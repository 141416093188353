import { useFindOrdersQuery, useSearchContracts } from "../../api/benefit";
import { NoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/NoApprovedBenefitsBanner";
import { useNoApprovedBenefitsBanner } from "../../components/NoApprovedBenefitBanner/useNoApprovedBenefitsBanner";
import { PageLayout } from "../../components/PageLayout";
import { Bikes } from "./components/Bikes";
import { Empty } from "./components/Empty";

export function MyBikesPage() {
  const { shouldShowNoApprovedBenefitsBanner } = useNoApprovedBenefitsBanner();

  const { data: orders } = useFindOrdersQuery({
    sortDirection: "desc",
    sortField: "updated_at",
    limit: 100,
  });
  const contractIds = orders
    ? (orders.items
        .map((o) => o.currentBikeBenefitContractId)
        .filter(Boolean) as string[])
    : [];
  const { data: contracts } = useSearchContracts(
    {
      xFilterIds: JSON.stringify(contractIds),
      limit: contractIds.length,
    },
    {
      enabled: contractIds.length > 0,
    },
  );

  return (
    <>
      {shouldShowNoApprovedBenefitsBanner ? <NoApprovedBenefitsBanner /> : null}
      <PageLayout>
        {orders && contracts ? (
          orders.items.length > 0 ? (
            <Bikes orders={orders.items} contracts={contracts.items} />
          ) : (
            <Empty hasActivation={!shouldShowNoApprovedBenefitsBanner} />
          )
        ) : null}
      </PageLayout>
    </>
  );
}
