/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { UserShopRead } from "./UserShopRead";
import {
  UserShopReadFromJSON,
  UserShopReadFromJSONTyped,
  UserShopReadToJSON,
  UserShopReadToJSONTyped,
} from "./UserShopRead";

/**
 *
 * @export
 * @interface PaginatedUserShopOut
 */
export interface PaginatedUserShopOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserShopOut
   */
  total: number;
  /**
   *
   * @type {Array<UserShopRead>}
   * @memberof PaginatedUserShopOut
   */
  items: Array<UserShopRead>;
}

/**
 * Check if a given object implements the PaginatedUserShopOut interface.
 */
export function instanceOfPaginatedUserShopOut(
  value: object,
): value is PaginatedUserShopOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedUserShopOutFromJSON(json: any): PaginatedUserShopOut {
  return PaginatedUserShopOutFromJSONTyped(json, false);
}

export function PaginatedUserShopOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedUserShopOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(UserShopReadFromJSON),
  };
}

export function PaginatedUserShopOutToJSON(json: any): PaginatedUserShopOut {
  return PaginatedUserShopOutToJSONTyped(json, false);
}

export function PaginatedUserShopOutToJSONTyped(
  value?: PaginatedUserShopOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(UserShopReadToJSON),
  };
}
