/* tslint:disable */
/* eslint-disable */
/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const PaymentServiceProvider = {
  Dummy: "dummy",
  VismaPay: "visma_pay",
  Stripe: "stripe",
} as const;
export type PaymentServiceProvider =
  (typeof PaymentServiceProvider)[keyof typeof PaymentServiceProvider];

export function instanceOfPaymentServiceProvider(value: any): boolean {
  for (const key in PaymentServiceProvider) {
    if (Object.prototype.hasOwnProperty.call(PaymentServiceProvider, key)) {
      if (
        PaymentServiceProvider[key as keyof typeof PaymentServiceProvider] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentServiceProviderFromJSON(
  json: any,
): PaymentServiceProvider {
  return PaymentServiceProviderFromJSONTyped(json, false);
}

export function PaymentServiceProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentServiceProvider {
  return json as PaymentServiceProvider;
}

export function PaymentServiceProviderToJSON(
  value?: PaymentServiceProvider | null,
): any {
  return value as any;
}

export function PaymentServiceProviderToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): PaymentServiceProvider {
  return value as PaymentServiceProvider;
}
