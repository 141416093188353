/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Payer } from "./Payer";
import {
  PayerFromJSON,
  PayerFromJSONTyped,
  PayerToJSON,
  PayerToJSONTyped,
} from "./Payer";
import type { SaleInvoiceLineCreate } from "./SaleInvoiceLineCreate";
import {
  SaleInvoiceLineCreateFromJSON,
  SaleInvoiceLineCreateFromJSONTyped,
  SaleInvoiceLineCreateToJSON,
  SaleInvoiceLineCreateToJSONTyped,
} from "./SaleInvoiceLineCreate";

/**
 *
 * @export
 * @interface ReturnProcessInvoicingAction
 */
export interface ReturnProcessInvoicingAction {
  /**
   *
   * @type {SaleInvoiceLineCreate}
   * @memberof ReturnProcessInvoicingAction
   */
  lineIn: SaleInvoiceLineCreate;
  /**
   *
   * @type {string}
   * @memberof ReturnProcessInvoicingAction
   */
  notes: string;
  /**
   *
   * @type {Payer}
   * @memberof ReturnProcessInvoicingAction
   */
  payer: Payer;
}

/**
 * Check if a given object implements the ReturnProcessInvoicingAction interface.
 */
export function instanceOfReturnProcessInvoicingAction(
  value: object,
): value is ReturnProcessInvoicingAction {
  if (!("lineIn" in value) || value["lineIn"] === undefined) return false;
  if (!("notes" in value) || value["notes"] === undefined) return false;
  if (!("payer" in value) || value["payer"] === undefined) return false;
  return true;
}

export function ReturnProcessInvoicingActionFromJSON(
  json: any,
): ReturnProcessInvoicingAction {
  return ReturnProcessInvoicingActionFromJSONTyped(json, false);
}

export function ReturnProcessInvoicingActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReturnProcessInvoicingAction {
  if (json == null) {
    return json;
  }
  return {
    lineIn: SaleInvoiceLineCreateFromJSON(json["line_in"]),
    notes: json["notes"],
    payer: PayerFromJSON(json["payer"]),
  };
}

export function ReturnProcessInvoicingActionToJSON(
  json: any,
): ReturnProcessInvoicingAction {
  return ReturnProcessInvoicingActionToJSONTyped(json, false);
}

export function ReturnProcessInvoicingActionToJSONTyped(
  value?: ReturnProcessInvoicingAction | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    line_in: SaleInvoiceLineCreateToJSON(value["lineIn"]),
    notes: value["notes"],
    payer: PayerToJSON(value["payer"]),
  };
}
