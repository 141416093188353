/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime";
import type { Payment } from "./Payment";
import {
  PaymentFromJSON,
  PaymentFromJSONTyped,
  PaymentToJSON,
  PaymentToJSONTyped,
} from "./Payment";

/**
 *
 * @export
 * @interface PaginatedPaymentOut
 */
export interface PaginatedPaymentOut {
  /**
   *
   * @type {number}
   * @memberof PaginatedPaymentOut
   */
  total: number;
  /**
   *
   * @type {Array<Payment>}
   * @memberof PaginatedPaymentOut
   */
  items: Array<Payment>;
}

/**
 * Check if a given object implements the PaginatedPaymentOut interface.
 */
export function instanceOfPaginatedPaymentOut(
  value: object,
): value is PaginatedPaymentOut {
  if (!("total" in value) || value["total"] === undefined) return false;
  if (!("items" in value) || value["items"] === undefined) return false;
  return true;
}

export function PaginatedPaymentOutFromJSON(json: any): PaginatedPaymentOut {
  return PaginatedPaymentOutFromJSONTyped(json, false);
}

export function PaginatedPaymentOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPaymentOut {
  if (json == null) {
    return json;
  }
  return {
    total: json["total"],
    items: (json["items"] as Array<any>).map(PaymentFromJSON),
  };
}

export function PaginatedPaymentOutToJSON(json: any): PaginatedPaymentOut {
  return PaginatedPaymentOutToJSONTyped(json, false);
}

export function PaginatedPaymentOutToJSONTyped(
  value?: PaginatedPaymentOut | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    total: value["total"],
    items: (value["items"] as Array<any>).map(PaymentToJSON),
  };
}
